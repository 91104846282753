import React, { useState, useEffect } from "react";
import SupportFooter from "./SupportFooter";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { baseURL } from "../../global/global";
import axios from 'axios';

// Redux
import { connect } from "react-redux";
import {
  fetchSupportArticle,
  fetchSupportVideo,
  getArticleCategory,
  getArticleContent,
} from "../../actions/support";
import SearchingResult from "./SearchingResult";
import Loader from "../loader/Loader";
import { Helmet } from "react-helmet";

const Support = ({
  support: { loading, support_videos, support_articles },
  auth,
  loader,
}) => {
  const [videos, setVideos] = useState([]);
  const [articles, setArticles] = useState([]);
  const [showVideoModal, setShowVideoModal] = useState({
    open: false,
    url: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [is_client, setIsClient] = useState(false);
  const [searchingData, setSearchingData] = useState([]);
  const [showSearchingComponent, setShowSearchingComponent] = useState(false);
  const history = useHistory();
  const [pdf, setPdf] = useState([]);

  useEffect(() => {
    if (auth.user) {
      setIsClient(
        auth.loading || !auth.user.is_client ? false : auth.user.is_client
      );
    }
  }, [auth.user, auth.loading]);


  useEffect(() => {
    const data = {
      user_id: auth.user.user_id
    }
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    }
    axios.post(`${baseURL}api/traning-pdf`, data, config)
      .then((res) => {
        if (res.data.status === true) {
          setPdf(res.data.data)
        }
      })

  }, [])


  useEffect(() => {
    if (showVideoModal.url.length > 0) {
      document.getElementById("player").innerHTML = showVideoModal.url;

      document.getElementById("player").childNodes[0].style.width = "100%";
      document.getElementById("player").childNodes[0].style.height = "100%";
    }
  }, [showVideoModal.url]);

  useEffect(() => {
    if (support_videos.length > 0) {
      setVideos(loading || !support_videos ? [] : support_videos);
    }
  }, [support_videos, loading]);

  useEffect(() => {
    if (support_articles.length > 0) {
      setArticles(loading || !support_articles ? [] : support_articles);
    }
  }, [loading, support_articles]);

  const searchVideosAndArticles = () => {
    if (searchValue === "" || articles.length < 1) return;
    let data = [];

    articles.map((value) =>
      value.items.map((item) => {
        if (
          value.tags.includes(searchValue.toLowerCase()) ||
          item.title.includes(searchValue.toLowerCase())
        ) {
          data.push({
            article_id: value.id,
            item_id: item.id,
            image: item.feature_url,
            title: item.title,
            created: item.created,
          });
        }
      })
    );
    setShowSearchingComponent(true);
    setSearchingData([...data]);
    window.scrollBy(0, 500);
  };

  useEffect(() => {
    if (searchValue === "") {
      setShowSearchingComponent(false);
    }
  }, [searchValue]);

  if (is_client === true) {
    return <Redirect to="/dashboard" />;
  }




  return (
    <div>
      <Helmet>
        <title> PodReel | Support</title>
      </Helmet>
      <div>
        <div id="rep-content" style={{ display: "block" }}>
          <section id="sup-banner" className="section">
            <div className="container-fluid px-4">
              <div className="row align-items-center">
                <div className="col colmn-1 col-lg-6 col-12 mb-lg-0 mb-5">
                  <div className="title-area text-lg-left text-center mb-2 pb-1">
                    <h3>What do you want to know?</h3>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchVideosAndArticles();
                    }}
                  >
                    <div className="input-group mx-lg-0 mx-auto">
                      <input
                        id="search-tpc"
                        className="form-control"
                        type="search"
                        name="search"
                        placeholder="Search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <div
                        className="input-group-append"
                        style={{ cursor: "pointer" }}
                        onClick={() => searchVideosAndArticles()}
                      >
                        <svg width="24px" height="24px" viewBox=" 0 0 24 24">
                          <use xlinkHref="assets/images/search.svg#search-ico" />
                        </svg>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col colmn-2 col-lg-6 col-12">
                  <div className="img-wrapper text-lg-left text-center">
                    <img
                      className="img-fluid"
                      src="../assets/images/illo-create-platform1.png"
                      alt="Graphics"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div style={{ width: "70%", margin: "auto", padding: "20px" }}>
            <h2 className="trainHead">Training Docs</h2>
            <div className="help" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
              {
                pdf.filter((val) => {
                  if (searchValue === "") {
                    return val
                  } else if (val.title.toLowerCase().includes(searchValue.toLowerCase())) {
                    return val
                  }
                }).map((p) => {
                  return (
                    <div className="main_pdf col-md-6" key={p.id}>
                      <li style={{ listStyle: "none", padding: "10px 0" }} ><span style={{ marginRight: "10px", color: "red" }}><i class="far fa-file-pdf"></i></span> <a href={p.pdf_url} target="new">{p.title}</a> </li>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {/* {articles.length < 1 ? (
            <section id="tpc-section" className="section">
              <div className="container-fluid">
                <div
                  className="row justify-content-center align-items-center"
                  style={{ height: "10rem" }}
                >
                  <div className="col-12 col-lg-9 col-md-7 mb-4 mx-auto">
                    <div className="title-area text-center">
                      <Loader />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            ) : showSearchingComponent ? (
            <SearchingResult
              searchingData={searchingData}
              setSearchingData={setSearchingData}
              setShowSearchingComponent={setShowSearchingComponent}
            />
           ) : (
            <>
              <section id="tpc-section" className="section bg-app">
                <div className="container-fluid px-4">
                  <div className="row">
                    <div className="col colmn-1 col-12 mb-4">
                      <div className="title-area text-center">
                        <h3 className="text-white">Important Topics</h3>
                      </div>
                    </div>
                    <div className="card-row d-flex flex-row flex-wrap w-100 mx-auto justify-content-center">
                      {articles.map((article) => (
                        <a
                          href="!#"
                          className="tpc-card card border-0 text-center w-100  m-2 mb-4"
                          key={article.id}
                          onClick={(e) => {
                            e.preventDefault();
                            return history.push(
                              `/support-article?category=${article.id}`
                            );
                          }}
                        >
                          <img
                            className="card-img-top w-auto mb-3"
                            src={article.logo}
                            alt="Icon"
                          />
                          <div className="card-body p-0">
                            <h5 className="card-title mb-0">{article.name}</h5>
                          </div>
                        </a>
                      ))}
                    </div>
                    <div className="col colmn-2 col-12">
                      <div className="btn-wrapper text-center">
                        <a
                          href="!#"
                          className="view-tpcs btn btn-3"
                          onClick={(e) => {
                            e.preventDefault();
                            return history.push(
                              `/support-article?category=${articles[0].id}`
                            );
                          }}
                        >
                          View All Topics
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="vid-tuts" className="section bg-app-1 section-pop-video">
                <div className="container-fluid px-4">
                  <div className="row ">
                    <div className="col colmn-1 col-12 mb-4">
                      <div className="title-area text-center">
                        <h3 className="text-white">Popular Video Tutorials</h3>
                      </div>
                    </div>
                    {videos.map((video) => (
                      <div
                        className="col colmn-4 col-xl-3 col-lg-6 col-sm-9 col-12 px-2 mb-xl-0 mt-3 mb-5 mx-auto training"
                        key={video.id}
                      >
                        <div className="card-wrapper">
                          <div className="img-wrapper mb-3">
                            <img
                              className="img-fluid poster w-100"
                              src={video.thumbnail}
                              alt="Tutorials"
                            />
                          </div>
                          <div className="details-wrapper row mx-0">
                            <a
                              href="!#"
                              className="play pr-3 align-self-center"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowVideoModal((value) => ({
                                  ...value,
                                  open: true,
                                  url: video.url,
                                }));
                              }}
                            >
                              <img
                                className="img-fluid"
                                src="./assets/images/play-button.svg"
                                alt="Icon"
                              />
                            </a>
                            <div className="meta-wrapper flex-grow-1">
                              <div className="date mb-1">
                                <span className="sm-txt text-white">
                                  {video.created}
                                </span>
                              </div>
                              <p
                                className="text-white title"
                                style={{ fontWeight: 600 }}
                              >
                                {video.title}
                              </p>
                            </div>
                            <div className="time">
                              <span className="sm-txt text-white">3:02</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          )} */}
        </div>

        <SupportFooter />

        {/* Watch Video Modal */}
        {showVideoModal.open ? (
          <div
            id="watch-video"
            className="modal fade show d-block"
            style={{ background: "rgba(0,0,0,0.9)" }}
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered mx-auto"
              role="document"
            >
              <div className="modal-content mx-auto">
                <button
                  type=" button"
                  className="close mb-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ opacity: 1 }}
                >
                  <img
                    className="img-fluid"
                    src="./assets/images/close.svg"
                    alt="Close"
                    onClick={() =>
                      setShowVideoModal((value) => ({
                        ...value,
                        open: false,
                        url: "",
                      }))
                    }
                  />
                </button>
                <div
                  className="modal-body border-0 p-0"
                // style={{ boxShadow: "none" }}
                >
                  <div className="vid-wrapper">
                    <div
                      className="plyr__video-embed"
                      id="player"
                      // data-plyr-provider="vimeo"
                      // data-plyr-embed-id="512159766"
                      style={{ width: "100%", height: "100%" }}
                    ></div>
                    {/* <video
                      id="tuts-player"
                      className="d-block w-100"
                      playsInline
                      controls
                    >
                      <source src={showVideoModal.url} type="video/mp4" />
                    </video> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* End Watch Video Modal */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  support: state.supportData,
  auth: state.auth,
  loader: state.loader.loader,
});

Support.propTypes = {
  fetchSupportVideo: PropTypes.func.isRequired,
  fetchSupportArticle: PropTypes.func.isRequired,
  support: PropTypes.object.isRequired,
  getArticleCategory: PropTypes.func.isRequired,
  getArticleContent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchSupportArticle,
  fetchSupportVideo,
  getArticleContent,
  getArticleCategory,
})(Support);
