import React, { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Helmet from "react-helmet";
import { useDropzone } from 'react-dropzone';
import axios from "axios";
import swal from "sweetalert";
import { connect, useSelector } from "react-redux";
import { baseURL } from "../../global/global";
import { saveUploadAudioUrl } from "../../actions/createPodreel";
import CreateHead from "./CreateHead";




function UploadSection(props) {
  const audioUrl = useSelector(state => state.createPodreel.audio_file_data.url);
  const [exten, setExten] = useState("Extension");
  const [size, setSize] = useState("fileSize");
  const [progress, setProgress] = useState("");
  const history = useHistory();



  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const roundOffSize = file.size / 1024 / 1024;
      const totalSize = roundOffSize.toFixed(2);
      const extn = file.name.split('.').pop();

      setSize(totalSize + 'Mb');
      setExten(extn);

      const formData = new FormData();

      if (file.size > 104857600 && extn === "mp3") {
        swal("Audio file is too large!", "Please select an audio file not more than 100Mb.");
      } else if (file.size <= 104857600 && extn === "mp3") {

        formData.append("audio", file);
        formData.append("user_id", props.auth.user.user_id);

      } else if (extn !== "mp3") {
        swal("This file is not supported!", "Please select .mp3 audio file.");
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        }
      };

      try {
        if (file.size < 104857600 && extn === "mp3") {
          axios.post(`${baseURL}api/audio-upload`, formData, config)
            .then((res) => {
              if (res.data.status === true) {
                props.dispatch(saveUploadAudioUrl(res.data.data));
                history.push({ pathname: "/ProfileSection" });
              }
            })
        }
      } catch (err) {
        console.log(err)
      }
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop });



  return (
    <>
      <Helmet>
        <title> PodReel | UploadSection</title>
      </Helmet>
      <CreateHead />
      <section className="create-prodcast-sec">
        <div className="container">

          <div className="row mt-lg-5">
            <div className="col-lg-12 col-md-12 mx-auto">
              <div className="create-tab">

                <nav>
                  <div className="nav nav-tabs d-flex justify-content-between" id="nav-tab" role="tablist">
                    <Link className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"><span>1</span>Upload</Link>
                    <Link className="nav-item nav-link" to={audioUrl === "" ? "#" : "/ProfileSection"} style={{ cursor: "auto" }}><span style={audioUrl === "" ? { cursor: "auto" } : { cursor: "pointer" }} >2</span>Audio</Link>
                    <Link className="nav-item nav-link" to="#" style={{ cursor: "auto" }} ><span style={audioUrl === "" ? { cursor: "auto" } : { cursor: "pointer" }}>3</span>Customize</Link>
                  </div>
                </nav>
                <div className="tab-content mt-lg-5 mt-md-5" id="nav-tabContent">
                  <div className="tab-pane text-white fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className="upload-body">


                      <label htmlFor="upload-audio" {...getRootProps()} style={{ width: "100%" }}>
                        <div className="main" >
                          <div className="upload-board" style={{ cursor: "pointer" }} >
                            <div className="upload-icon text-center ">
                              <label htmlFor="upload-audio">
                                <span><i className="fas fa-upload" style={{ cursor: "pointer" }} /></span></label><br />
                              {
                                progress > 0 ?
                                  <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{ width: progress + '%', backgroundColor: "#ea5e5f" }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                                  </div> : null
                              }
                              {/* onChange={handleAudioInput} */}
                              <input type="file" name="file" accept=".mp3" style={{ display: "none" }} {...getInputProps()} />

                              {exten === 'mp3' ? <h6>( {exten} , {size} )</h6> : <h6>( exten , size )</h6>}

                              <h6 style={{ color: '#fff' }}>.mp3 up to 1MB is allowed.</h6>
                              <div className="upload-audio mt-5">
                                <h5>Upload Audio</h5>
                              </div>
                            </div>

                          </div>
                        </div>
                      </label>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}


const mapStateToProps = (state) => ({
  auth: state.auth,
});


export default connect(mapStateToProps)(UploadSection);